import '../styles/portfolio.css';
import java from '../assets/java.svg';
import c from '../assets/c.svg';
import python from '../assets/python.svg';
import javaScript from '../assets/js.svg';
import kotlin from '../assets/kotlin.svg';
import nodeJs from '../assets/node-js.svg';
import tailwind from '../assets/tailwind.svg';
import react from '../assets/react.svg';
import mysql from '../assets/mysql.svg';
import mongoDb from '../assets/mongodb.svg';
import github from '../assets/github.svg';
import visualStudioCode from '../assets/visual_studio_code.svg';
import intellijIdea from '../assets/intellijidea.svg';
import phpStorm from '../assets/phpstorm.svg';
import androidStudio from '../assets/android-studio.svg';

function Portfolio() {
    return (
        <main>
            <section className="hero" id="home">
                <div>
                    <h1 id="portfolio-header">Emily Rose</h1>
                    <h2 id="portfolio-subheader">Software Developer</h2>
                </div>
                <div>
                    <h3>Full Stack Web Developer</h3>
                    <p>rose.emi.dr@gmail.com</p>
                    <p>Greater Toronto Area</p>
                    <a className="portfolio-link" href="https://www.linkedin.com/in/emil-rose/">LinkedIn</a>
                    <a className="portfolio-link" href="https://github.com/roseemi/">Github</a>
                </div>
                <span className="spinner" id="spinner-one"></span>
                <span className="spinner" id="spinner-two"></span>
                <span className="arrow" id="arrow-one"></span>
                <span className="arrow" id="arrow-two"></span>
            </section>
            <section className="about" id="profile">
                <div>
                    <div className="line"></div>
                    <p>
                        Hello! I'm <span>Emily</span>, a recent <span>Computer Programming</span> graduate from <span>Georgian College</span> as of August 2024 with a <span>4.0 GPA</span>, and made the <span>Dean's List every semester</span>.
                        <br/>
                        <br/>
                        I previously graduated from the <span>University of Toronto</span> with a <span>Bachelor Honours of Arts in Cognitive Science</span> where I studied human and AI cognition.
                        <br/>
                        <br/>
                        I have worked on websites since I was only 10 years old, and I'm happy to call it my profession. I am an experienced <span>Full Stack Web Developer</span> with 2+ years worth of professional and freelance experience. I'm an enthusiastic learner who is open to being challenged. 
                        <br/>
                        <br/>
                        My cumulative education at both institutions makes me a <span>strong software developer</span> with notable proficiency in <span>JavaScript, C#, Python, Java, Kotlin, and CSS</span>.
                        I am currently <span>open for full-time software developer positions</span>. 
                        <br/>
                        <br/>
                        Feel free to reach out to me at any of my <a href="#home">contact methods</a>.
                    </p>
                    <div className="line"></div>
                </div>
            </section>
            <section className="portfolio" id="projects">
                <div className="showcase">
                    <div className="tile lazy-background honeypot">
                        <div>
                            <h3>Honeypot Database App</h3>
                            <p>Developed for Bell's Geekfest 2023 Hackathon which won 2nd place, this application helps preserve online data privacy by redirecting malicious actors attempting a SQL injection to a decoy database.</p>
                            <a href="https://github.com/JayCBreak/bellHackathon2023" title="Image by vectorjuice on Freepik" className="portfolio-project-link">Github: <i className="fas fa-code"></i></a>
                            <ul>
                                <li className="project-list-item">Java</li>
                                <li className="project-list-item">FXML</li>
                                <li className="project-list-item">MySQL</li>
                                <li className="project-list-item">CSS</li>
                            </ul>
                        </div>
                    </div>
                    <div className="tile lazy-background travel">
                        <div>
                            <h3>Travel E-Commerce</h3>
                            <p>Collects data from travel APIs, populates a database, and "sells" various travel packages.
                                Fully supports client sessions, a cart system, an auto-email system, and working Stripe integration.
                            </p>
                            <a href="https://github.com/kaseadoun/kanoo-ecommerce" title="Image by vectorjuice on Freepik" className="portfolio-project-link">Github: <i className="fas fa-code"></i></a>
                            <ul>
                                <li className="project-list-item">C#</li>
                                <li className="project-list-item">ASP.NET</li>
                                <li className="project-list-item">MySQL</li>
                                <li className="project-list-item">JSON</li>
                                <li className="project-list-item">CSS</li>
                                <li className="project-list-item">JavaScript</li>
                            </ul>
                        </div>
                    </div>
                    <div className="tile lazy-background todo">
                        <div>
                            <h3>To-Do Android Application</h3>
                            <p>A classic to-do mobile application built using Android Studio and Kotlin. The application uses Firebase Firestore as its database for persistent data,
                                so entries and their statuses are always saved.
                            </p>
                            <a href="https://github.com/roseemi/To_Do_App_Android" title="Image by vectorjuice on Freepik" className="portfolio-project-link">Github: <i className="fas fa-code"></i></a>
                            <p></p>
                            <a href="https://www.youtube.com/watch?v=-QuAUog2gGg" target="_blank" className="portfolio-project-link">YouTube Demo:</a>
                            <ul>
                                <li className="project-list-item">Kotlin</li>
                                <li className="project-list-item">XML</li>
                                <li className="project-list-item">NoSQL</li>
                            </ul>
                        </div>
                    </div>
                    <div className="tile lazy-background quiz">
                        <div>
                            <h3>Quiz Formatter</h3>
                            <p>This python script will format a quiz inside a Word document when you run it. It will standardise the styling, spacing, and structure of the document.</p>
                            <a href="https://github.com/roseemi/Quiz-Formatter" title="Image by vectorjuice on Freepik" className="portfolio-project-link">Github: <i className="fas fa-code"></i></a>
                            <ul>
                                <li className="project-list-item">Python</li>
                            </ul>
                        </div>
                    </div>
                    <div className="tile lazy-background retro">
                        <div>
                            <h3>Retro Storefront</h3>
                            <p>A multipage website all about Beanie Babies. Contains a storefront for browsing products. This is unofficial, and no products are able to be bought.</p>
                            <a href="https://github.com/roseemi/Retro-E-Commerce" title="Image by vectorjuice on Freepik" className="portfolio-project-link">Github: <i className="fas fa-code"></i></a>
                            <ul>
                                <li className="project-list-item">HTML</li>
                                <li className="project-list-item">CSS</li>
                                <li className="project-list-item">JavaScript</li>
                            </ul>
                        </div>
                    </div>
                    <div className="tile lazy-background bot">
                        <div>
                            <h3>Discord Bot</h3>
                            <p>This discord bot is a light-hearted pick-me-up bot that will say kind things to the user on command, made for use in small personal servers.</p>
                            <a href="https://github.com/roseemi/Emotional-Support-Discord-Bot" title="Image by vectorjuice on Freepik" className="portfolio-project-link">Github: <i className="fas fa-code"></i></a>
                            <ul>
                                <li className="project-list-item">Python</li>
                                <li className="project-list-item">Discord API</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="skills" id="skills">
                <h3>Technologies</h3>
                <div className="technologies">
                <div className="icon-container"><img src={javaScript} className="icon" alt="JavaScript" />JavaScript</div>
                    <div className="icon-container"><img src={nodeJs} className="icon" alt="Node.JS" />Node.JS</div>
                    <div className="icon-container"><img src={react} className="icon" alt="React" />React</div>
                    <div className="icon-container"><img src={python} className="icon" alt="Python" />Python</div>
                    <div className="icon-container"><img src={java} className="icon" alt="Java" />Java</div>
                    <div className="icon-container"><img src={kotlin} className="icon" alt="Kotlin" />Kotlin</div>
                    <div className="icon-container"><img src={c} className="icon" alt="C#" />C#</div>
                    <div className="icon-container"><img src={tailwind} className="icon" alt="Tailwind" />Tailwind</div>
                    <div className="icon-container"><img src={mysql} className="icon" alt="MySQL" />MySQL</div>
                    <div className="icon-container"><img src={mongoDb} className="icon" alt="MongoDB" />MongoDB</div>
                    <div className="icon-container"><img src={github} className="icon" alt="GitHub" />Github</div>
                    <div className="icon-container"><img src={visualStudioCode} className="icon" alt="VS Code" />VS Code</div>
                    <div className="icon-container"><img src={intellijIdea} className="icon" alt="IntelliJ" />IntelliJ</div>
                    <div className="icon-container"><img src={phpStorm} className="icon" alt="PHPStorm" />PHPStorm</div>
                    <div className="icon-container"><img src={androidStudio} className="icon" alt="Android Studio" />Android Studio</div>
                </div>
            </section>
            <footer>
                <div className="contacts">
                </div>
                <div className="extra">
                    <p>Design and code by Emily Rose</p>
                    <p>Vector illustrations by <a href="https://www.freepik.com/author/vectorjuice">VectorJuice</a> on FreePik</p>
                </div>
            </footer>
        </main>
    );
}

export default Portfolio;